export const environment = {
  production: false,
  envName: 'dev',
  baseUrl: 'https://my-dev.mheducation.com',
  apiBaseUrl: 'https://authoring-api-dev.assess.nonprod.mheducation.com/v1',
  apiBaseUrlV2: 'https://authoring-api-dev.assess.nonprod.mheducation.com/v2',
  wirisUrl: 'https://wiris-tinymce-dev.assess.nonprod.mheducation.com/7.5.0.1392',
  wirisMathUrl: 'https://wiris-dev.assess.nonprod.mheducation.com/',
  mmsUrl: 'https://mms-api-dev.assess.nonprod.mheducation.com',
  bentoUrl: 'https://dev.metadata-api.nonprod.mheducation.com',
  assessAuthUrl: 'https://x2z7r7cdzb.execute-api.us-east-1.amazonaws.com/dev',
  assessApiUrl: 'https://authoring-api-dev.assess.nonprod.mheducation.com/v1',
  assessBaseUrl: 'https://authoring-api-dev.assess.nonprod.mheducation.com/v1',
  standardsUrl: 'https://standards-api-dev.assess.nonprod.mheducation.com',
  tinymceUrl: 'https://dle-cdn.mheducation.com/mhe/tinymce/6.8.2',
  /* TODO (eslint): Naming convention */
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  QTIurl: 'https://air-exchange-api-dev.assess.nonprod.mheducation.com',
  newRelicAsset: 'assets/newrelic/newrelic-nonprod.js',
  assessDeliveryBaseUrl: 'https://assess-apiv2-dev.assess.nonprod.mheducation.com',
  assessBaseUrlv2: 'https://assess-apiv2-dev.assess.nonprod.mheducation.com/v2',
  airExchangeUrl: 'https://air-exchange-api-dev.assess.nonprod.mheducation.com',
  enableEntityMenu: true,
  externalContentSubscriptions: {
    rubricSubscriptionXid: 'urn:com.mheducation.openlearning:integration.internal.lars:dev.us-east-1:lars_subscription:7ad237aa-17f9-4b02-bbe7-d959a2856a3c',
    courseResourcesSubscriptionXid: 'urn:com.mheducation.openlearning:integration.internal.lars:dev.us-east-1:lars_subscription:bf4285ff-b1c4-4029-a083-d56d61c2e2b9'
  }
};
